<div class="sdds-card ">

    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Trucks</h6>
        <h6 class="sdds-card-sub-headline">List of all trucks</h6>
    </div>

    <div class="sdds-divider-light-border-top"></div>
    <div class="sdds-card-body">
        <table class="sdds-table">
            <thead class="sdds-table__header">
                <tr class="sdds-table__row">
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">ID</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Name</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Fuel Type</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Number Of Axis</th>
                    <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2"></th>
                </tr>
            </thead>
            <tbody class="sdds-table__body">
                <tr class="sdds-table__row" *ngFor="let truck of trucks">
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{truck.Id}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{truck.Name}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{truck.FuelType}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{truck.NumberOfAxis}}</td>
                    <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 action-buttons">
                        <img src="assets\icons\edit.svg" (click)="editTruck(truck.Id)" />
                        <img src="assets\icons\trash.svg" (click)="modalService.openConfirmation(truck.Id, deleteTruck.bind(this))"/>                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="sdds-card-footer">
        <button class="sdds-btn sdds-btn-sm sdds-btn-primary" (click)="createTruck()">Add Truck</button>
    </div>
</div>
<app-confirmation-modal
    size="xs"
    modalId="deletion-modal"
    title="Delete Truck"
    message="Are you sure you want to delete this truck ?"
></app-confirmation-modal>