<nav class='sdds-nav'>

    <div class='sdds-nav__left'>
        <button (click)="openMobileMenu()" class="sdds-nav__mob-menu-btn">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.97 6.998a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 15.982a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 24.966a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1Z"
                    fill="currentColor" />
            </svg>
        </button>
        <div class='sdds-nav__app-name' routerLink="welcome" style="cursor: pointer;">KD - COMPONENTS MATCHING</div>
    </div>

    <div class='sdds-nav__center'>
        <ul class='sdds-nav__inline-menu'>

            <li class='sdds-nav__item' routerLink="axles" routerLinkActive="sdds-nav__item--active">
                <a class='sdds-nav__item-core'>
                    <p class='sdds-nav__item-core-text'>Eixos</p>
                </a>
            </li>

        </ul>
    </div>

    <div class='sdds-nav__right'>
        <a class='sdds-nav__item sdds-nav__app-logo' href='#'></a>
    </div>
</nav>