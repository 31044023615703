<div class="sdds-card welcome-page">
    <div class="card-img">
        <img class="sdds-card-img" src="https://dw1u92ia6bs84.cloudfront.net/Images/1e70c35fb37b46548526b7f58c1e39f4.jpg">
    </div>
    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Bem-vindo ao KD - Components Matching</h6>
        <!-- <h6 class="sdds-card-sub-headline">A simple angular front-end project.</h6> -->
    </div>

    <div class="sdds-divider-light-border-top"></div>
    <div class="sdds-card-body">Sistema para leitura e verificação das etiquetas dos C.U.s de uma remessa. 
    </div>
</div>