import { Component, OnInit, ɵdevModeEqual } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../shared/services/modal.service';
import { Truck } from './models/truck.model';
import { TrucksService } from './services/trucks.service';

@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit {

  trucks: Truck[] = [];
  deletionId: number = 0;

  constructor(
    private router: Router,
    private truckService: TrucksService,
    public modalService: ModalService
  ) { }

  ngOnInit(): void {    
    this.truckService.getTrucks().subscribe((trucksResponse) => {
      this.trucks = trucksResponse;      
    });
  }

  editTruck(truckId: number): void {
    this.router.navigate([`trucks/edit/${truckId}`]);
  }

  deleteTruck(id: number): void {    
    if (id) {
      let truckIndex: number = this.trucks.findIndex(x => x.Id == id);
      this.trucks.splice(truckIndex, 1);
    }    
  }

  createTruck(): void {
    let newTruckId = this.truckService.createTruck();

    this.router.navigate([`trucks/edit/${newTruckId}`]);
  }

}
