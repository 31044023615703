import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrucksService } from '../services/trucks.service';

@Component({
  selector: 'app-edit-truck',
  templateUrl: './edit-truck.component.html',
  styleUrls: ['./edit-truck.component.scss']
})
export class EditTruckComponent implements OnInit {

  formTruckEdit: FormGroup = new FormGroup({});

  constructor(
    private truckService: TrucksService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let truckId = parseInt(this.route.snapshot.params['id']);
    let truckEdit = this.truckService.getById(truckId);

    this.formTruckEdit = new FormGroup({
      Id: new FormControl(truckEdit.Id),
      Name: new FormControl(truckEdit.Name),
      FuelType: new FormControl(truckEdit.FuelType),
      NumberOfAxis: new FormControl(truckEdit.NumberOfAxis),
    });
  }

  saveChanges(): void {
    this.truckService.setTruck(this.formTruckEdit.value);
    this.router.navigate(['trucks']);
  }

}
