import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  collapsed = true;

  menuItems = [
    {
      label: "Axles",
      route: "/axles",
      subMenu: null
    }
  ];
  
  @Input() mobileMenuOpen = false;
  @Output() eventFromSidebar = new EventEmitter<any>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  closeMobileMenu() {
    this.eventFromSidebar.emit({
      type: 'showMobileMenu',
      value: false,
    });
  }

  closeSidebar(): void {
    this.collapsed = false
  }

  toggleParentClass(event: any, className: string) {
    const hasClass = event.target.parentElement.classList.contains(className);

    if (hasClass) {
      this.renderer.removeClass(event.target.parentElement, className);
    } else {
      this.renderer.addClass(event.target.parentElement, className);
    }
  }

  closeMenu(menu: any) {
    if (!menu.subMenu) {
      this.eventFromSidebar.emit({
        type: 'showMobileMenu',
        value: false,
      });
    }
  }

}
