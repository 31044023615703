import { Injectable } from '@angular/core';
import { Order } from '../models/order.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Axle } from '../models/axle.model';
import { AxleScan } from '../models/axle-scan.mode';
import { Result } from 'src/app/shared/models/result.model';

@Injectable({
  providedIn: 'root'
})
export class AxlesService {

  constructor(
    private http: HttpClient
  ) { }

  private kdexApi = environment.kdexApiUrl;
  private pokaApi = environment.pokaApiUrl;

  get(): Observable<Result<Order[]>> {
     return this.http.get<Result<Order[]>>(`${this.kdexApi}/ComponentsMatching/GetAxles`);
  }

  getAxleInformation(popId: string): Observable<Result<Axle[]>> {
    return this.http.get<Result<Axle[]>>(`${this.kdexApi}/ComponentsMatching/GetAxleCu?popId=${popId}`);
  }

  getAxles(): Observable<AxleScan[]>{
    return this.http.get<AxleScan[]>(`${this.pokaApi}/Axle/getAll`);
  }

  getScannedByPopId(popId: string): Observable<AxleScan> {
    return this.http.get<AxleScan>(`${this.pokaApi}/Axle/getByPopId?popId=${popId}`);
  }

  insert(axle: AxleScan) : Observable<any> {
    return this.http.post(`${this.pokaApi}/Axle/insert`, axle);
  }

}