import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Axle } from '../../models/axle.model';
import { AxlesService } from '../../services/axles.service';
import { AxleScan } from '../../models/axle-scan.mode';
import { Result } from 'src/app/shared/models/result.model';

@Component({
  selector: 'app-axle-verification',
  templateUrl: './axle-verification.component.html',
  styleUrls: ['./axle-verification.component.scss']
})
export class AxleVerificationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private axleService: AxlesService
  ) { }

  public isPopIdValid: boolean = true;
  public message: string = "";

  public showModal: boolean = false;

  public popId: string = '';
  public cuMatching: Axle[] = [];

  public isCuOk: boolean = false;
  public isOtherLabelsOk: boolean = false;

  ngOnInit(): void {
    this.popId = this.route.snapshot.paramMap.get('popId')!;
    this.axleAlreadyScanned();
  }

  loadAxle(): void {
    this.axleService.getAxleInformation(this.popId).subscribe({
      next: (data: Result<Axle[]>) => {
        if (data.success) {
          this.cuMatching = data.content;
          setTimeout(() => {
            this.focusNextElement('popId_1', true);
          }, 500);
          console.log(this.cuMatching);
        } else {
          this.isPopIdValid = false;
          this.message = "O PopId não foi encontrado no banco de dados, verifique o valor lido. Em caso de persistência no erro, contante o suporte."
        }
      },
      error: (err) => {
        console.log('ERROR');
      }
    });
  }

  axleAlreadyScanned(): void {
    this.axleService.getScannedByPopId(this.popId).subscribe({
      next: (data: any) => {
        if (data) {
          this.isPopIdValid = false;
          this.message = "Já foi realizada a leitura deste PopId. Confirme se a leitura foi realizada corretamente e se a etiqueta está correta."
        } else {
          this.loadAxle();
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  verifyPopId(event: any, sequence: number) {
    const popId = (event.target as HTMLInputElement).value.trim();

    if (popId) {
      if (popId == this.popId) {
        this.focusNextElement('codCu_' + sequence, false);
      } else {
        this.modalAction(true);
        this.focusNextElement('popId_' + sequence, true);
      }
    }
  }

  verifyCodCu(event: any, selectedCodCu: number, sequence: number) {
    const codCu = (event.target as HTMLInputElement).value.trim();

    if (codCu) {
      if (codCu == selectedCodCu.toString()) {
        if (sequence == this.cuMatching.length) {
          this.isCuOk = true;
          setTimeout(() => {
            this.focusNextElement('small_1', false);
          }, 500);
        } else {
          sequence++;
          this.focusNextElement('popId_' + sequence, false);
        }
      } else {
        this.modalAction(true);
        this.focusNextElement('codCu_' + sequence, true);
      }
    }
  }

  verifySmallLabel(event: any, selectedLabel: string, sequence: number) {
    const smallLabel = (event.target as HTMLInputElement).value.trim();

    if (smallLabel) {
      if (smallLabel == selectedLabel) {
        this.focusNextElement('color_' + sequence, false);
      } else {
        this.modalAction(true);
        this.focusNextElement('small_' + sequence, true);
      }
    }
  }

  verifyColorLabel(event: any, selectedLabel: string, sequence: number) {
    const colorLabel = (event.target as HTMLInputElement).value.trim();

    if (colorLabel) {
      if (colorLabel == selectedLabel) {
        if (sequence == this.cuMatching.length) {
          this.isOtherLabelsOk = true;
          this.save();
        } else {
          sequence++;
          this.focusNextElement('small_' + sequence, false);
        }
      } else {
        this.modalAction(true);
        this.focusNextElement('color_' + sequence, true);
      }
    }
  }

  focusNextElement(elementId: string, clearElement: boolean) {
    const element = document.getElementById(elementId) as HTMLInputElement;
    element?.focus();
    if (clearElement) {
      element.value = '';
    }
  }

  save() {

    const packingDate = this.cuMatching[0].packingDate.split(' ')[0].split('/');

    let batchInfo = "";
    for(const cu of this.cuMatching) {
      batchInfo += `"C.U: ${cu.batch}, Etiqueta Pequena: ${cu.smallLabel}, Etiqueta Colorida: ${cu.colorLabel}. `;
    }

    const axleScanned: AxleScan = {
      id: 0,
      popId: this.popId,
      batch: this.cuMatching[0].batch,
      model: this.cuMatching[0].model,
      packingDate: `${packingDate[2]}-${packingDate[0]}-${packingDate[1]}`,
      scanDate: this.scanDate(),
      user: '',
      notes: batchInfo
    };

    this.axleService.insert(axleScanned).subscribe({
      next: (data: boolean) => {
        if (data) {
          console.log('SAVED');
          this.router.navigate([`axles`]);
        } else {
          console.log('NOT SAVED');
        }
      },
      error: (err) => {
        console.log('ERROR');
      }
    });
  }

  scanDate(): string {
    const data = new Date();

    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const dia = String(data.getDate()).padStart(2, '0');

    return `${ano}-${mes}-${dia}`;
  }

  modalAction(active: boolean) : void {
    this.showModal = active;
    if (active) {
      setInterval(() => {
        this.showModal = false;
      }, 5000);
    }
  }

}