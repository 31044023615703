import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-truck-inline',
  templateUrl: './edit-truck-inline.component.html',
  styleUrls: ['./edit-truck-inline.component.scss']
})
export class EditTruckInlineComponent {

  constructor() { }

}
