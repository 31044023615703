import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { TrucksComponent } from './trucks/trucks.component';
import { EditTruckComponent } from './trucks/edit-truck/edit-truck.component';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { EditTruckInlineComponent } from './trucks/edit-truck-inline/edit-truck-inline.component';
import { AxlesComponent } from './axles/axles.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AxleVerificationComponent } from './axles/verification/axle-verification/axle-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    TrucksComponent,
    EditTruckComponent,
    ConfirmationModalComponent,
    EditTruckInlineComponent,
    AxlesComponent,
    SidebarComponent,
    HeaderComponent,
    AxleVerificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add the Schema
})
export class AppModule { }