<div id="cardBarcode" class="sdds-card">

    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Código de Barras</h6>
        <h6 class="sdds-card-sub-headline">Realize a leitura do PopId para iniciar o processo.</h6>    
    </div>
        
    <div class="sdds-card-body">
        <div class="div-input">
            <div class="input-barcode">
                <sdds-textfield ngDefaultControl autofocus placeholder="Escaneie/Digite" [(ngModel)]="popId" type="text" >
                    <span slot="sdds-label">Pop Id</span>
                </sdds-textfield>
            </div>&nbsp;&nbsp;&nbsp;
            <div style="align-self: end;">
                <button class="sdds-btn sdds-btn-lg sdds-btn-primary" style="margin-bottom: 4px;" (click)="startVerification()">Verificar</button>
            </div>
        </div>
    </div>

    <div class="sdds-card-footer">
    </div>
</div>

<div id="cardBatches" class="sdds-card">
    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Remessas/Pedidos</h6>
        <h6 class="sdds-card-sub-headline"></h6>    
    </div>

    <div class="sdds-card-body">
        <div style="overflow-x: auto;">
            <table class="sdds-table sdds-table--divider">
                <thead class="sdds-table__header">
                    <tr class="sdds-table__row">
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Remessa</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">PopId</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Modelo</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">CUs</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Packing Date</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Data de Leitura</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="sdds-table__row" *ngFor="let order of orders | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }" [ngClass]="{'finalized': order.status}">
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.batch}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.popId}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.model}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.codCu}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.packingDate}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.scanDate ? order.scanDate : '-'}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{order.status ? 'FINALIZADO' : 'PENDENTE'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls (pageChange)="currentPage = $event" class="pagination"></pagination-controls>
    </div>
</div>