<div class="sdds-card">
    <div class="sdds-card-header">
        <h4 class="sdds-card-headline">Processo de Leitura - PopId: {{popId}}</h4>
    </div>    
</div>

<div class="sdds-message sdds-message__type-warning sdds-message__icon-active sdds-message__extended-active" *ngIf="!isPopIdValid">

    <svg class="sdds-message-icon sdds-message-icon--warning" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.25175 9.919V7.75H10.7367V9.919L10.5167 12.603H9.47175L9.25175 9.919ZM9.21875 13.439H10.7807V14.935H9.21875V13.439Z" fill="#F1C21B"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.875C10.2332 0.875 10.4469 1.00479 10.5545 1.21165L18.6795 16.8367C18.7803 17.0304 18.7726 17.2627 18.6592 17.4494C18.5459 17.636 18.3434 17.75 18.125 17.75H1.875C1.65662 17.75 1.45408 17.636 1.34075 17.4494C1.22742 17.2627 1.21974 17.0304 1.32049 16.8367L9.44549 1.21165C9.55306 1.00479 9.76684 0.875 10 0.875ZM2.90445 16.5H17.0956L10 2.85471L2.90445 16.5Z" fill="#F1C21B"></path>
    </svg>
  
      <h4 class="sdds-message-single">
        Atenção
      </h4>
      
    <p class="sdds-message-extended">
      {{message}}
    </p>
  
</div>


<div id="div-etiqueta-cu" class="sdds-card" *ngIf="isPopIdValid && !isCuOk">

    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Leitura das etiquetas de PopId e C.U.</h6>
    </div>

    <div class="sdds-card-body">

        <div *ngFor="let axle of cuMatching" class="div-linha">
            
            <div>
                <label>{{axle.cuDescription}}</label>
            </div>
            
            <div class="div-input" [id]="'div_seq_' + axle.sequence">
                <div>
                    <input class="sdds-textfield-input" placeholder="PopId" type="text"
                        [id]="'popId_' + axle.sequence" (input)="verifyPopId($event, axle.sequence)">
                </div>
                &nbsp;&nbsp;&nbsp;                
                <div>
                    <input class="sdds-textfield-input" placeholder="C.U" type="text"
                        [id]="'codCu_' + axle.sequence"
                        (input)="verifyCodCu($event, axle.cuMona, axle.sequence)">
                </div>
            </div>

            <br>

        </div>

    </div>

</div>

<div id="div-etiqueta-cu" class="sdds-card" *ngIf="isPopIdValid && isCuOk">

    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Leitura das etiquetas pequenas e coloridas</h6>
    </div>

    <div class="sdds-card-body">

        <div *ngFor="let axle of cuMatching" class="div-linha">
            
            <div>
                <label>{{axle.cuDescription}}</label>
            </div>
            
            <div class="div-input" [id]="'div_seq_' + axle.sequence">

                <div>
                    <input class="sdds-textfield-input" placeholder="Etiqueta Pequena" type="text"
                        [id]="'small_' + axle.sequence" (input)="verifySmallLabel($event, axle.smallLabel.toString(), axle.sequence)">
                </div>

                &nbsp;&nbsp;&nbsp;
                
                <div>
                    <input class="sdds-textfield-input" placeholder="Etiqueta Colorida" type="text"
                        [id]="'color_' + axle.sequence" (input)="verifyColorLabel($event, axle.colorLabel.toString(), axle.sequence)">
                </div>    

            </div>
            <br>

        </div>

    </div>

</div>

<!-- Warning Modal (add show/hide class to show or hide) -->
<div id="divModal" class="sdds-modal-backdrop" [ngClass]="showModal ? 'show' : 'hide'">
    <!-- Add size class here, for example sdds-modal-md -->
        <div class='sdds-modal sdds-modal-sm'>
            <div class="sdds-modal-header">
                <div class="sdds-modal-headline">
                    <h5>Atenção!</h5>
                </div>
                <span class="sdds-modal-btn" (click)="modalAction(false);"></span>
            </div>
            <div class="sdds-modal-body">
                <p>
                    A leitura realizada não corresponde com o valor esperado. Verifique se o valor lido e a etiqueta estão corretos.
                </p>
            </div>
            <!-- <div class="sdds-modal-actions">
                <div class="sdds-modal-actions">
                    <button class="sdds-btn sdds-btn-primary sdds-btn-md">Save</button>
                    <button class="sdds-btn sdds-btn-secondary sdds-btn-md">Cancel</button>
                </div>
            </div> -->
        </div>
    </div>