import { Injectable } from '@angular/core';
import { Observable, of, VirtualTimeScheduler } from 'rxjs';
import { Truck } from '../models/truck.model';

@Injectable({
  providedIn: 'root'
})
export class TrucksService {

  private mockTrucks: Truck[] = [
    {
      Id: 1,
      Name: 'Truck1',
      FuelType: 'Diesel',
      NumberOfAxis: 2,
    },
    {
      Id: 2,
      Name: 'Truck2',
      FuelType: 'Gas',
      NumberOfAxis: 3,
    },
    {
      Id: 3,
      Name: 'Truck3',
      FuelType: 'Diesel',
      NumberOfAxis: 3,
    },
    {
      Id: 4,
      Name: 'Truck4',
      FuelType: 'Diesel', 
      NumberOfAxis: 1,
    },
    {
      Id: 5,
      Name: 'Truck5',
      FuelType: 'Eletric',
      NumberOfAxis: 1,
    },
    
  ];

  constructor() { }

  getTrucks(): Observable<Truck[]> {
    return of(this.mockTrucks);
  }

  getById(id: number): Truck {
    let truck = this.mockTrucks.find(x => x.Id === id);
    
    if (truck) {
      return truck;
    }
    return {} as Truck;
  }

  setTruck(truck: Truck): void {
    let truckIndex = this.mockTrucks.findIndex(x => x.Id === truck.Id);
    this.mockTrucks[truckIndex] = truck;
  }

  createTruck(): number {
    let newTruckId = this.mockTrucks[this.mockTrucks.length - 1].Id + 1;

    this.mockTrucks.push({
      Id: newTruckId,
      Name: `New Truck ${newTruckId}` ,
      FuelType: '',
      NumberOfAxis: 1
    });

    return newTruckId;
  }
}
