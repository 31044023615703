import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { AxlesComponent } from './axles/axles.component';
import { AxleVerificationComponent } from './axles/verification/axle-verification/axle-verification.component';
import { TrucksComponent } from './trucks/trucks.component';

const routes: Routes = [
  { path: '', redirectTo:'welcome', pathMatch:'full' },
  { path: 'welcome', component: WelcomeComponent, pathMatch:'full' },
  { path: 'axles', component: AxlesComponent, pathMatch:'full' },
  { path: 'axles/verification/:popId', component: AxleVerificationComponent, pathMatch:'full' },
  { path: 'truck', component: TrucksComponent, pathMatch:'full' },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }