<div class="sdds-card">
    <div class="sdds-card-header">
        <h6 class="sdds-card-headline">Editing Truck</h6>
        
    </div>
    <div class="sdds-card-body">
        <form [formGroup]="formTruckEdit" novalidate>

            <div class="fields-form">
                <div style="width:208px">
                    <sdds-textfield ngDefaultControl formControlName="Id" type="text">
                        <span slot="sdds-label">ID</span>
                    </sdds-textfield>
                </div>
            
                <div style="width:208px">
                    <sdds-textfield ngDefaultControl formControlName="Name" type="text">
                        <span slot="sdds-label">Name</span>
                    </sdds-textfield>
                </div>
            
                <div style="width:208px">
                    <sdds-textfield ngDefaultControl formControlName="FuelType" type="text">
                        <span slot="sdds-label">Fuel Type</span>
                    </sdds-textfield>
                </div>
            
                <div style="width:208px">
                    <sdds-textfield ngDefaultControl formControlName="NumberOfAxis" type="text">
                        <span slot="sdds-label">Number Of Axis</span>
                    </sdds-textfield>
                </div>
            </div>
        
        </form>
    </div>
    <div class="sdds-card-footer">
        <sdds-button type="primary" size="sm" text="Save Changes" (click)="saveChanges()"></sdds-button>
    </div>
</div>
