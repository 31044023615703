import { Component, OnInit } from '@angular/core';
import { AxlesService } from './services/axles.service';
import { ModalService } from '../shared/services/modal.service';
import { Router } from '@angular/router';
import { Order } from './models/order.model';
import { AxleScan } from './models/axle-scan.mode';
import { Result } from '../shared/models/result.model';

@Component({
  selector: 'app-axles',
  templateUrl: './axles.component.html',
  styleUrls: ['./axles.component.scss']
})
export class AxlesComponent implements OnInit {

  public orders: Order[] = [];
  public scannedAxles: AxleScan[] = [];

  currentPage = 1; // Página atual
  itemsPerPage = 10; // Itens por página

  popId: string | undefined;

  constructor(
    private router: Router,
    private axleService: AxlesService,
    public modalService: ModalService
  ) { }

  ngOnInit(): void {

    this.loadAxlesFromKdex();

  }

  startVerification() {
    this.router.navigate([`axles/verification/${this.popId}`]);
  }

  loadAxlesFromKdex() {
    this.axleService.get().subscribe({
      next: (data: Result<Order[]>) => {
        if (data.success) {
          this.orders = data.content;
          this.loadScannedAxles(); 
        } else {
          console.log('Erro');
        }
      },
      error: (err) => {
        console.log('ERROR');
      } 
    });
  }

  loadScannedAxles() {
    this.axleService.getAxles().subscribe({
      next: (data: AxleScan[]) => {
        this.scannedAxles = data;this.setStatusForAxles();
      },
      error: (err) => {
        console.log('ERROR');
      } 
    });
  }

  setStatusForAxles() {
    this.orders.forEach(popid => {
      this.scannedAxles.forEach(axle => {
        if (axle.popId == popid.popId) {
          popid.status = true;
          const data = axle.scanDate.split('T')[0].replace(/-/g, "/").split("/");
          popid.scanDate = `${data[2]}/${data[1]}/${data[0]}`;
        }
      });
    });
  }

}
